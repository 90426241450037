import React, { useEffect, useState } from "react";
import {
	AutocompleteArrayInput,
	AutocompleteInput,
	showNotification,
	useDataProvider,
	usePermissions,
} from "react-admin";
import { Permissions } from "../../../../permissions";
import { GET_OFF_RESOURCE } from "../../../../../../providers/nestjs_crud";
import { useDispatch } from "react-redux";

export function JobTagInput(props) {
	const [state, setState] = useState("INITIAL");
	const [tags, setTags] = useState([]);
	const dataProvider = useDataProvider();
	const { permissions } = usePermissions();
	const dispatch = useDispatch();

	useEffect(() => {
		if (state === "INITIAL") {
			setState("FETCHING_DATA");
			dataProvider(
				GET_OFF_RESOURCE,
				Permissions.missionJobTag.feature
			)
				.then((message) => {
					setState("FETCHED");
					setTags(message.data);
				})
				.catch((error) => {
					setState("ERROR_FETCHING_DATA");
					dispatch(showNotification("Errore durante la fetch dei tag", 'error'));
				});
		}
	}, [dataProvider, dispatch, state]);

	if (
		!permissions ||
		!permissions.includes(
			`${Permissions.missionJobTag.feature}-${Permissions.missionJobTag.actions.ReadAll}`
		)
	) {
		return null;
	}

	if (!props.label || !props.source) {
		console.error("JobTagInput: label and source must be defined");
		return null;
	}

	if(state !== "FETCHED") {
		return null;
	}

	if (props.multiple) {
		return (
			<AutocompleteArrayInput
				translateChoice={false}
				allowEmpty={false}
				parse={(value) => {
					return value.includes(null) ? [] : value;
				}}
				{...{ ...props, choices: tags.map((tag) => ({ id: tag.id, name: tag.name })) }}
			/>
		);
	} else {
		return (
			<AutocompleteInput
				{...{...props, choices: tags.map((tag) => ({ id: tag.id, name: tag.name }))}}
			/>
		);
	}
}
