import React from 'react';
import { Datagrid, List, TextField, TextInput, ReferenceInput, AutocompleteInput, Filter, TopToolbar, useGetOne, Loading, ReferenceField, NumberField, BooleanField } from 'react-admin';
import { Permissions } from '../../permissions/permissions';
import Button from '@material-ui/core/Button';
import { useHistory } from "react-router-dom";
import AddIcon from '@material-ui/icons/Add';
import { Typography } from '@material-ui/core';
import { ChevronRight } from '@material-ui/icons';

function ItemSaleablePriceListFilters(props) {
    return (
        <Filter {...props}>
        <TextInput type="tel" label="item Id" source="item.id" alwaysOn />
        <TextInput label="item name" source="item.name" alwaysOn />
        <ReferenceInput
          alwaysOn
          filterToQuery={searchText => {
            if (!searchText)
              return {};

            return {
              internalName: searchText
            }
          }}
          label="Categoria ecommerce"
          source="itemSaleable.clientCategoryId||eq"
          reference={`${Permissions.clientCategory.feature}`} >
          <AutocompleteInput
            emptyText="Tutte"
            optionText="internalName"
            optionValue="id" />
        </ReferenceInput>
        </Filter>
    );
}

function ItemSaleablePriceListActions(props) {
    let history = useHistory();

    return (
        <TopToolbar {...props}>
            <Button variant="contained" color="primary" startIcon={<AddIcon/>} onClick={() => {
                history.push(`/itemSaleablePriceList/create?parentId=${props.filterValues['priceListId||eq']}`);
            }}>CREATE</Button>
        </TopToolbar>
    )
}

export function ItemSaleablePriceListList(props) {
    const params = new URLSearchParams(props.location.search);
    const filter = params.get('filter');
    
    const { data, loading, error } = useGetOne(`priceList`, JSON.parse(filter)["priceListId||eq"]);

    if(loading || !data || error) {
        return (<Loading />);
    }

    return (
        <>
            <Typography variant='h5' style={{display:'flex', alignItems:'center'}}>
                {data.name}
                <ChevronRight />
                Item Vendibili
            </Typography>
            <List
                {...props}
                title={`${data.name} > Item Vendibili`}
                actions={<ItemSaleablePriceListActions />}
                filters={<ItemSaleablePriceListFilters />}
                sort={{ field: 'item.name', order: 'ASC'} }
                filterDefaultValues={{"quantity||gte": 1}}
                perPage={25}
            >
                <Datagrid rowClick="edit">
                    <TextField sortable={props.sortable} source="order" label="Ordine" />
                    <TextField sortable={props.sortable} source="item.id" label="Item ID" />
                    <TextField sortable={props.sortable} source="item.name" label="Item" />
                    <ReferenceField sortable={props.sortable} label="Categoria Ecommerce" source="itemSaleable.clientCategoryId" reference="clientCategory" link="show">
                        <TextField source="internalName" />
                    </ReferenceField>
                    <NumberField sortable={props.sortable} locales="it-IT" options={{ style: 'currency', currency: 'EUR', maximumFractionDigits: 2 }} label="Prezzo di default" source="itemSaleable.price"></NumberField>
                    <BooleanField sortable={props.sortable} source="differentPrice" label="Prezzo differente" />
                    <NumberField sortable={props.sortable} locales="it-IT" options={{ style: 'currency', currency: 'EUR', maximumFractionDigits: 2 }} label="Prezzo" source="price"></NumberField>
                </Datagrid>
            </List>
        </>
    )
}