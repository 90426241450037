export const MANUAL_TASK_TYPES = ['formText', 'formDropdown', 'formPhotos', 'formCallPlanner'];

export const JOB_TYPE_VALUES = [
  'fridgeRefill',
  'coffeeRefill',
  'waterCheck',
  'fruitDelivery',
  'cateringSetup',
  'clientDelivery',
  'marketingDelivery',
  'storeTransfer',
  'coffeeMaintenance',
  'vehicleMaintenance',
  'cateringMaterials',
  'coffeeDelivery',
  'deliveryOrPickUp',
  'fridgeSetup',
  'fridgeUnistall',
  'hubActivities',
  'hubBackoffice',
  'hubTraining',
  'fridgeMantenance'
];

export const EXECUTIONS = [
    'optional',
    'mandatory',
    'mandatory-before-next-task',
];

export const STAGES_MAP = {
    'pre-drive': [
        'pre-pickLoad',
        'post-pickLoad',
      ],
    mission:[
        'pre-inventory',
        'post-inventory_pre-doUnload',
        'post-doUnload_pre-load',
        'post-load_pre-driveUnload',
        'post-driveUnload',
      ],
    'post-drive': [
        'pre-dropUnload',
        'post-dropUnload',
    ],
    'general-bottom': ['general-bottom'] ,
};

export const missionStatesValidForCreateOrUpdateJobTask = [
  'DRAFT',
  'TO_BE_PLANNED',
  'PLANNING',
  'PLANNED',
  'DELIVERING',
  'IN_PROGRESS',
  'RETURNING',
];