import React from 'react';
import { Edit, SimpleForm, NumberInput,DateTimeInput, TextInput, ReferenceInput, required, AutocompleteInput, SelectInput  } from 'react-admin';
import BaseEdit from '../../inheritance/BaseEdit';
import { Permissions } from '../../../permissions/permissions';

export function MissionEdit(props) {
	return (
	<Edit debounce={1000} {...props}>
		<SimpleForm redirect="show">
			<NumberInput label="Luoghi" source='placeId'  disabled />
            <DateTimeInput 
                locales="it-IT" 
                validate={[required('campo obbligatorio')]} 
                label="Data massima di inizio" 
                source="maxExecutionDate" />
            <DateTimeInput locales="it-IT" label="Data stimata di inizio" source="estimatedStartDate" />
            <NumberInput 
				label="Stima tempi morti in minuti" 
				source="downtimeDuration" 
				defaultValue={15}
          		validate={[required("campo obbligatorio")]}
			/>
            <NumberInput validate={[required('campo obbligatorio')]} label="Valore missione" source="value" />
            <TextInput label="Commento" source="comment" />
            <ReferenceInput
                enableGetChoices={val => {
                    return !!(val && val['mainUser.label'] && val['mainUser.label'].length >= 3);
                }}
                filterToQuery={(searchText) => {
                    if (!searchText) return {};

                    return {
                        "mainUser.label": searchText,
                    };
                }}
                label="Corriere"
                source="deliveryManId"
                reference={Permissions.deliveryMan.feature}
                allowEmpty
            >
                <AutocompleteInput
                    shouldRenderSuggestions={val => {
                        return val && val.length >= 3;
                    }}
                    optionText={(record) => {
                        if (!record || !record.id) {
                            return "cancel";
                        }
                        return `${
                            record ? `${record.mainUser.id} - ${record.mainUser.label}` : ""
                        }`;
                    }}
                    optionValue="id"
                />
                </ReferenceInput>
                <ReferenceInput
					enableGetChoices={(val) => {
						return !!(
							val &&
							val["mainUser.label"] &&
							val["mainUser.label"].length >= 3
						);
					}}
					filterToQuery={(searchText) => {
						if (!searchText) return {};

						return {
							"mainUser.label": searchText,
						};
					}}
					label="Corriere suggerito"
					source="suggestedDeliveryManId"
					reference={Permissions.deliveryMan.feature}
					allowEmpty
				>
					<AutocompleteInput
						shouldRenderSuggestions={(val) => {
							return val && val.length >= 3;
						}}
						optionText={(record) => {
							if (!record || !record.id) {
								return "cancel";
							}
							return `${
								record
									? `${record.mainUser.id} - ${record.mainUser.label}`
									: ""
							}`;
						}}
						optionValue="id"
					/>
				</ReferenceInput>
				<SelectInput
					source="priority"
					label="Priorità"
					initialValue={'medium'}
					value={'medium'}
					choices={[
						{ id: 'low', name: 'Bassa' },
						{
							id: 'medium',
							name: 'Media',
						},
						{
							id: 'high',
							name: 'Alta',
						},
						{
							id: 'critical',
							name: 'Critical',
						},
					]}
				/>
			<BaseEdit />
		</SimpleForm>
	</Edit>
)};