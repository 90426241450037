import React from 'react';
import { FormDataConsumer } from 'react-admin';
import { FormText } from './typeSpecificComponents/formText'
import { FormDropdown } from './typeSpecificComponents/formDropdown'
import { FormPhotos } from './formPhotos/formPhotosInput'

export function DataProviderInputDispatcher(props) {
    return <FormDataConsumer>
        {(formDataProps) => {
             switch(formDataProps.formData.type) {
                case 'formText':
                case 'formCallPlanner':
                    return (
                        <FormText record={formDataProps.formData}/>
                    );
                case 'formDropdown':
                    return (
                        <FormDropdown record={formDataProps.formData} />
                    );
                case 'formPhotos':
                    return (
                        <FormPhotos formDataProps={formDataProps} dispatcherProps={props}/>
                    );
                default:
                    return null;
            }
        }}
    </FormDataConsumer>
}