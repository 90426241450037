import React from "react";
import { Typography, Box } from "@material-ui/core";
import { FormDropdownProviderData } from "./formDropdown/FormDropdownProviderData";
import { FormPhotosProviderData } from "./formPhotos/FormPhotosProviderData";
import { ReferenceField, TextField } from "react-admin";
import { Permissions } from "../../../../../../permissions/permissions";

export function ProviderData(props) {
  return (
    <Box
      display="flex"
      flexDirection="column"
      style={{ marginBottom: "0.5em" }}
    >
      <Typography variant="caption" style={{ color: "rgba(0, 0, 0, 0.54)" }}>
        {"Provider data"}
      </Typography>
      <Data {...props} />
    </Box>
  );
}

function Data(props) {
  if (!props.record) {
    return null;
  }

  switch (props.record.type) {
    case "formPhotos":
      return <FormPhotosProviderData data={props.record.providerData} />;
    case "formDropdown":
      return <FormDropdownProviderData data={props.record.providerData} />;
    case "formText":
    case "formCallPlanner":
      return "-";
    case "load":
    case "doUnload":
    case "driveUnload":
    case "dropUnload":
    case "driveLoad":
      return (
        <>
          <Typography variant="caption">
            <i>{`Transfer`}</i>
          </Typography>
          {props.record.providerData.transfer ? (
            <ReferenceField
              {...props}
              source="providerData.transfer.id"
              reference={`${Permissions.transfer.feature}`}
              link={"show"}
            >
              <TextField source="id" />
            </ReferenceField>
          ) : (
            "-"
          )}
        </>
      );
    case "inventory":
      return (
        <>
          <Box style={{ marginBottom: "0.5em" }}>
            <Typography variant="caption">
              <i>{`Warehouse location`}</i>
            </Typography>
            <br />
            {props.record.providerData.warehouseLocation ? (
              <ReferenceField
                {...props}
                source="providerData.warehouseLocation.id"
                reference={`${Permissions.warehouseLocation.feature}`}
                link={"show"}
              >
                <TextField source="id" />
              </ReferenceField>
            ) : (
              "-"
            )}
          </Box>
          <Typography style={{ marginBottom: "0.5em" }} variant="caption">
            <i>{`Item count mode`}</i>
            <br />
            {props.record.providerData.itemCountMode ? (
              <Typography variant="subtitle2">
                <TextField
                  style={{ fontSize: "inherit" }}
                  {...props}
                  source="providerData.itemCountMode"
                />
              </Typography>
            ) : (
              "-"
            )}
          </Typography>
          <Typography style={{ marginBottom: "0.5em" }} variant="caption">
            <i>{`Lot count mode`}</i>
            <br />
            {props.record.providerData.lotCountMode ? (
              <Typography variant="subtitle2">
                <TextField
                  style={{ fontSize: "inherit" }}
                  {...props}
                  source="providerData.lotCountMode"
                />
              </Typography>
            ) : (
              "-"
            )}
          </Typography>
        </>
      );
    case "ddt":
      return (
        <>
          <Box style={{ marginBottom: "0.5em" }}>
            <Typography variant="caption">
              <i>{`Transfer di carico`}</i>
            </Typography>
            <br />
            {props.record.providerData.transfer.load ? (
              <ReferenceField
                {...props}
                source="providerData.transfer.load.id"
                reference={`${Permissions.transfer.feature}`}
                link={"show"}
              >
                <TextField source="id" />
              </ReferenceField>
            ) : (
              "-"
            )}
          </Box>
          <Box style={{ marginBottom: "0.5em" }}>
            <Typography variant="caption">
              <i>{`Transfer di scarico`}</i>
            </Typography>
            <br />
            {props.record.providerData.transfer.unload ? (
              <ReferenceField
                {...props}
                source="providerData.transfer.unload.id"
                reference={`${Permissions.transfer.feature}`}
                link={"show"}
              >
                <TextField source="id" />
              </ReferenceField>
            ) : (
              "-"
            )}
          </Box>
        </>
      );
    default:
      return (
        <Typography variant="subtitle2">
          {JSON.stringify(props.record.providerData)}
        </Typography>
      );
  }
}
