import React from 'react';
import { Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';  
import { getColorForTaskStatus } from '../utils'
import { Typography, Card, AppBar, Toolbar } from '@material-ui/core';
import DoneIcon from '@material-ui/icons/Done';
import CachedIcon from '@material-ui/icons/Cached';
import { DDTTask } from './DDT';
import { DoUnloadTask }  from './DoUnloadTask';
import { DriveTask }  from './DriveTask';
import { DropUnloadTask }  from './DropUnloadTask';
import { InventoryTask }  from './InventoryTask';
import { LoadTask }  from './LoadTask';
import { FormTextTask } from './FormTextTask';
import { FormDropdownTask } from './FormDropdownTask';
import { FormPhotosTask } from './FormPhotosTask';
import { DropLoadTask }  from './DropLoadTask';
import { FormCallPlannerTask } from './FormCallPlannerTask';

const HIDDEN_TASK_TYPES = ['unavailableTask']

const useStyles = makeStyles((theme) => ({
    root: {
        alignItems: 'flex-start', 
        flexWrap: 'nowrap'
    },
    taskStatus: {
        width: '60px',
        marginRight: theme.spacing(1.3)
    },
    hidden: {
        display: 'none'
    }
}));

export function TaskType({ task, onDone, showHiddenTask }) {
    const classes = useStyles();
    const isHidden = !showHiddenTask && HIDDEN_TASK_TYPES.includes(task.type);

    return (
        <Grid container item xs={12} className={` ${isHidden ? classes.hidden : ""} ${classes.root}`} >
            <StatusCard task={task}  />
            <Grid item xs={true}>
                {task.type === 'unavailableTask' && <UnavailableTask label={task.label} />}
                {task.type === 'inventory' && <InventoryTask  task={task} />}
                {task.type === 'load' && <LoadTask onDone={onDone} task={task} />}
                {task.type === 'doUnload' && <DoUnloadTask onDone={onDone} task={task} />}
                {['driveLoad', 'driveUnload'].includes(task.type) && <DriveTask onDone={onDone} task={task} />}
                {task.type === 'dropUnload' && <DropUnloadTask onDone={onDone} task={task}/>}
                {task.type === 'dropLoad' && <DropLoadTask onDone={onDone} task={task}/>}
                {task.type === 'ddt' && <DDTTask config={task.config} />}
                {task.type === 'formText' && <FormTextTask onDone={onDone}  task={task} config={task.config} />}
                {task.type === 'formDropdown' && <FormDropdownTask onDone={onDone} task={task} config={task.config} />}
                {task.type === 'formPhotos' && <FormPhotosTask onDone={onDone} task={task} config={task.config}/>}
                {task.type === 'formCallPlanner' && <FormCallPlannerTask onDone={onDone} task={task} config={task.config}/>}

            </Grid>
        </Grid>
    )
}

const UnavailableTask = (props) => {
    return (
        <Card>
            <AppBar color="default" position="static">
                <Toolbar>
                    <Typography variant="h5">
                        {props.label}
                    </Typography>
                </Toolbar>
            </AppBar>
        </Card>
    )
}

const StatusCard = (props) => {
    const classes = useStyles();
    const isTaskUnavailableOrGeneralBottom = props.task.type === 'unavailableTask' || props.task.jobStage.name === 'general-bottom';
    const status = isTaskUnavailableOrGeneralBottom ? '' : props.task.status
    const isExecutionMandatory = ['mandatory-before-next-task', 'mandatory'].includes(props.task.execution);
    const borderRed = !isTaskUnavailableOrGeneralBottom && isExecutionMandatory && status !== 'DONE';
    const backgroundColor = getColorForTaskStatus(status);
    return <Card className={classes.taskStatus}>
                <AppBar position="static" style={{
                    backgroundColor: backgroundColor[300],
                    border: borderRed && `4px solid red`
                }}>
                    <Toolbar style={{
                        justifyContent: 'center'
                    }}>
                        {status === 'DONE' && <DoneIcon fontSize='large' style={{color: '#000' }} />}
                        {status === 'WIP' && <CachedIcon fontSize='large' style={{color: '#000'}} />}
                        {borderRed && <span style={{color: 'red', position: 'absolute', top: 0, left: 0, padding: '4px', fontSize: '20px'}}>*</span>}
                    </Toolbar>
                </AppBar>
            </Card>
}

