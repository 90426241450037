import React from "react";
import { SimpleShowLayout, TextField, Show } from "react-admin";
import Aside from "../../../inheritance/BaseAsideWithUser";
import { Typography, Chip, makeStyles } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
	root: {
		"display": "flex",
		"flexWrap": "wrap",
		"& > *": {
			margin: theme.spacing(0.5),
		},
	},
}));

export function MissionJobTagShow(props) {
	return (
		<Show
			aside={<Aside props={props} record={props.record}></Aside>}
			{...props}
		>
			<SimpleShowLayout>
				<TextField label="Name" source="name" />
				<MissionJobTypeShow />
			</SimpleShowLayout>
		</Show>
	);
}

const MissionJobTypeShow = ({ record }) => {
	const classes = useStyles();
	return (
		<div>
			<Typography variant="caption">Job Types</Typography>
			<div className={classes.root}>
				{record.jobTypes.map((jobType) => (
					<Chip key={jobType} label={jobType} />
				))}
			</div>
		</div>
	);
};
