import React, { useState } from 'react';
import { Show, SimpleShowLayout, TextField, useDataProvider, useRefresh, showNotification, ShowController, Loading } from 'react-admin';
import { Button, Typography, CircularProgress, Box } from '@material-ui/core';
import { getColorForJobStatus } from './utils'
import { useDispatch } from 'react-redux';
import { Permissions } from '../../../../permissions/permissions';
import { GET_OFF_RESOURCE_PLAIN } from '../../../../providers/nestjs_crud';
import { Grid, Container } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';  
import { TaskType } from './components/TaskType';
import { useHistory } from "react-router-dom";
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';

const useStyles = makeStyles((theme) => ({
    root: {
      flexGrow: 1,
      padding: theme.spacing(1)
    },
    tasks: {
        alignItems: 'flex-start', 
        flexWrap: 'nowrap'
    },
    taskStatus: {
        width: '60px',
        marginRight: theme.spacing(1.3)
    },
    btn: {
        marginTop: theme.spacing(2),
    }
}));

export const DeliveryManMissionJobShow = (props) => {
    const classes = useStyles();
    const refresh = useRefresh();
    const [showHiddenTask, setShowHiddenTask] = useState(false)

    return (
    <ShowController {...props}>
        {controllerProps => !controllerProps.loaded ? <Loading /> :
        <Container maxWidth="xl" className={classes.root}>
            <Grid container spacing={3}>
                <Grid item xl={12} lg={12} md={12} xs={12}>
                    <Show {...props}>
                        <SimpleShowLayout>
                            <Actions label="Stato"></Actions>
                            <TextField sortable={false} label='Tipologia' source="type" />
                            <TextField sortable={false} label='Destinazione' source="destination.label" />
                            <TextField sortable={false} label='Note sulla posizione' source="warehouseLocation.deliveryManNote" />
                            <TextField sortable={false} label='Tempo stimato' source="durationMinutes" />
                            <TextField sortable={false} label='commento' source="executionComment" />
                            <OpenLockerAndLayout />
                        </SimpleShowLayout>
                    </Show>
                </Grid>
            </Grid>
            <Grid container spacing={3}>
                {controllerProps.record && 
                controllerProps.record.tasks && 
                controllerProps.record.tasks.map((task, index) => (
                    <TaskType showHiddenTask={showHiddenTask} onDone={refresh} key={index} task={task}></TaskType>
                ))}
            </Grid>
            <Button 
                fullWidth
                variant='contained'
                color='primary'
                className={classes.btn}
                onClick={() => setShowHiddenTask(!showHiddenTask)}
                startIcon={showHiddenTask ? <KeyboardArrowUpIcon/> : <KeyboardArrowDownIcon/> }
            >
                {showHiddenTask ? 'Nascondi task non disponibili' : 'Mostra tutti i task'}
            </Button>
        </Container>   
        }
    </ShowController>
)};

function LayoutButton (props) {
    const history = useHistory();
    return  <Button
        style={{marginRight: '16px'}}
        color='primary' 
        size='medium' 
        variant='contained' 
        onClick={() => history.push(`/mission_rider/layout-list/${props.record.layout.id}/show`)}>
        Apri layout
    </Button>  
}

function OpenLockerAndLayout (props) {
    const [state, setState] = useState('INITIAL')
    const refresh = useRefresh();
    const dispatch = useDispatch();
    const dataProvider = useDataProvider();
    const IsLayout = props.record && props.record.layout

    if( props.record && 
        props.record.warehouseLocation && 
        props.record.warehouseLocation.lockerId
    ) {   
        if (state === 'FETCHING_DATA'){
            return ( 
            <Box textAlign='center'>
                <CircularProgress size={25} />
            </Box>  
            )
        }

        const {id, warehouseLocation } = props.record 
        return (
            <Box>
                {IsLayout && <LayoutButton {...props}/>}
                <Button 
                    size='medium' 
                    variant='contained'
                    color='primary'
                    onClick={() => {
                        setState('FETCHING_DATA')
                        dataProvider(GET_OFF_RESOURCE_PLAIN, `${Permissions.deliveryManMissionJob.feature}/${id}/open-locker/${warehouseLocation.lockerId}`)
                        .then((rawResponse) => {
                            const response = JSON.parse(rawResponse)
                            if(response.success) {
                                dispatch(showNotification(`azione: Apri frigo completata`));
                                refresh();
                            } else if(response.errors) {
                                const message = response.errors.map(({constraints}) => {
                                    return Object.keys(constraints).map(key => constraints[key]).join(', ');
                                }).join(', ');

                                throw new Error(message)
                            } else {
                                throw new Error('unknown error')
                            }
                            
                        })
                        .catch(error => {
                            setState('INITIAL')
                            dispatch(showNotification(`Errore durante azione: Apri frigo ${error}`, 'error'));
                        })
                    }}
                    >
                    Apri frigo
                </Button>
            </Box>
           
        );
    }

    if(IsLayout) {
        return  <LayoutButton {...props}/>
    }

    return null
}

function Actions (props) {
    const [state, setState] = useState('INITIAL')
    const refresh = useRefresh();
    const dispatch = useDispatch();
    const dataProvider = useDataProvider();
    if(props.record && props.record.status) {
        const backgroundColor = getColorForJobStatus(props.record.status);
        if (state === 'FETCHING_DATA'){
            return ( 
            <Box textAlign='center'>
                <CircularProgress size={25} />
            </Box>  
            )
        }

        const {id, actions } = props.record 
        return (
            <Box display='flex' justifyContent='start' alignItems="flex-end">
                <Box display="flex" flexDirection="column" alignItems="flex-start">
                    {props.label && <Typography  >
                        {props.label}   
                    </Typography>}
                    <Typography
                    component='span'
                        style={{ backgroundColor: backgroundColor[300], padding: '6px', borderRadius: '4px' }}>
                        {props.record.status}
                    </Typography>
                </Box>
                {props.record.actions && actions.map((action, index) => (
                    <Button 
                        size='medium' 
                        variant='contained'
                        style={{ marginLeft: '10px'}}
                        color='primary' 
                        key={index}
                        onClick={() => {
                            setState('FETCHING_DATA')
                            dataProvider(GET_OFF_RESOURCE_PLAIN, `${Permissions.deliveryManMissionJob.feature}/${id}/do-action/${action.key}`)
                            .then((rawResponse) => {
                                const response = JSON.parse(rawResponse)
                                if(response.success) {
                                    dispatch(showNotification(`azione: ${action.label} completata`));
                                    refresh();
                                } else if(response.errors) {
                                    const message = response.errors.map(({constraints}) => {
                                        return Object.keys(constraints).map(key => constraints[key]).join(', ');
                                    }).join(', ');

                                    throw new Error(message)
                                } else {
                                    throw new Error('unknown error')
                                }
                                
                            })
                            .catch(error => {
                                setState('INITIAL')
                                dispatch(showNotification(`Errore durante azione: ${action.label}. ${error}`, 'error'));
                            })
                        }}
                        >
                        {action.label}
                    </Button>
                ))}
            </Box>
        );
    }
    return null;
}

