import React from "react";
import {
	Edit,
	SimpleForm,
	TextInput,
	Toolbar,
	SaveButton,
	required,
} from "react-admin";
import { TypeInput } from "../task/components/TypeInput";
const UserEditToolbar = (props) => (
	<Toolbar {...props}>
		<SaveButton />
	</Toolbar>
);

export function MissionJobTagEdit(props) {
	return (
		<Edit {...props}>
			<SimpleForm toolbar={<UserEditToolbar />}>
				<TextInput label="Name" source="name" validate={[required()]} />
				<TypeInput label="Tipologia Servizio" source="jobTypes" multiple/>
			</SimpleForm>
		</Edit>
	);
}
