import React, { useState } from 'react';
import { Create, SimpleForm, TextInput,SelectInput, NumberInput, required } from 'react-admin';
import { JobStageSubStage  } from '../components/JobStageSubStage';
import { DataProviderInputDispatcher } from '../components/DataProviderInputDispatcher';

import { STAGES_MAP, EXECUTIONS, MANUAL_TASK_TYPES } from '../config';
import { TypeInput } from '../components/TypeInput';
import { JobTagInput } from '../../tags/components/JobTagInput';

export function TaskConfigCreate(props) {
    const [context, setContext] = useState('typeJob');
    return (
        <Create {...props}>
            <SimpleForm redirect={"show"}>
                <TextInput label="Label" source="label" validate={[required()]} />
                <SelectInput 
                    source='context' 
                    defaultValue={context} 
                    onChange={(e) => setContext(e.target.value)} 
                    choices={[{
                        id: 'typeJob',
                        name: "Tipologia di Servizio"
                    },{
                        id: 'jobTag',
                        name: "Tag servizio missione"
                    }]}
                />

                {context === 'typeJob' && <TypeInput label="typeJob" source="typeJob" validate={[required()]}/>}
                {context === 'jobTag' && <JobTagInput label="Job Tag" source="jobTagId" validate={[required()]}/>}
                <SelectInput
                    source="type"
                    label="type"
                    choices={MANUAL_TASK_TYPES.map((type) => ({id: type, name: type}))}
                    validate={[required()]}
                />
                <SelectInput
                    source="execution"
                    label="execution"
                    choices={EXECUTIONS.map((execution) => ({id: execution, name: execution}))}
                    validate={[required()]}
                />
                <SelectInput
                    source="jobStageName"
                    label="jobStageName"
                    choices={Object.keys(STAGES_MAP).map((stage) => ({id: stage, name: stage}))}
                    validate={[required()]}
                />
                <JobStageSubStage
                    stageSource="jobStageName"
                    source="jobStageSubStage"
                    label="jobStageSubStage"
                />
                <NumberInput label="Interstage Order" source="jobStageInterStageOrder" validate={[required()]} />
                <DataProviderInputDispatcher />
            </SimpleForm>
        </Create>
    );
}