import React from 'react';
import { SimpleShowLayout, TextField, ShowController, ShowView } from 'react-admin';
import Aside from '../../../../inheritance/BaseAsideWithUser';
import { ProviderData } from '../components/ProviderData'

export const TaskConfigShow = (props) => {

    return (
        <ShowController {...props}>
            {controllerProps => <ShowView {...props} {...controllerProps} aside={<Aside props={props} record={props.record}></Aside>} >
                <SimpleShowLayout>
                    <TextField label="label" source="label"></TextField>
                    <TextField label="typeJob" source="typeJob"></TextField>
                    <TextField label="Job Tag" source="jobTag.name"></TextField>
                    <TextField label="type" source="type"></TextField>
                    <TextField label="execution" source="execution"></TextField>
                    <TextField label="jobStageName" source="jobStageName"></TextField>
                    <TextField label="jobStageSubStage" source="jobStageSubStage"></TextField>
                    <TextField label="jobStageInterStageOrder" source="jobStageInterStageOrder"></TextField>
                    <ProviderData/>
                </SimpleShowLayout>
            </ShowView>}
        </ShowController>
    );
};
