import React from 'react';
import { List, Datagrid, TextField, ReferenceInput, AutocompleteInput, ReferenceField, NumberInput, AutocompleteArrayInput} from 'react-admin';
import Filter from '../../../inheritance/BaseFilter';
import ListActions from '../../../../components/ListActions';
import { Permissions } from '../../../../permissions/permissions';
import { TypeInput } from './task/components/TypeInput';

const status = [
  { id: 'TO_DO', name: 'TO_DO' },
  { id: 'IN_PROGRESS', name: 'IN_PROGRESS' },
  { id:'DONE', name: 'DONE' },
  { id: 'CANCELLED', name: 'CANCELLED' },
  { id: 'PAUSED', name: 'PAUSED' },
  { id: 'FAILED', name: 'FAILED' },
];

const JobManyFilter = (props) => (
    <Filter {...props}>
        <TypeInput label="Type" source="type"/>
        <ReferenceInput
            filterToQuery={searchText => {
                if(!searchText)
                    return {};

                return { id: searchText }
            }}
            label="Mission"
            source="missionId||eq"
            reference={`${Permissions.missionV2.feature}`}
        >
            <AutocompleteInput
                shouldRenderSuggestions={val => val && val.length >= 2 }
                optionText="id"
                optionValue="id" />
        </ReferenceInput>
        <ReferenceInput
            filterToQuery={searchText => {
                if(!searchText)
                    return {};

                return { name: searchText }
            }}
            label="Punto di Vendita"
            source="sellingPointId||eq"
            reference={`${Permissions.sellingPoint.feature}`}
            alwaysOn
        >
            <AutocompleteInput
                shouldRenderSuggestions={val => val && val.length >= 2 }
                optionText="name"
                optionValue="id" />
        </ReferenceInput>
        <ReferenceInput
            filterToQuery={searchText => {
                if(!searchText)
                    return {};

                return { name: searchText }
            }}
            label="Warehouse Location"
            source="warehouseLocationId||eq"
            reference={`${Permissions.warehouseLocation.feature}`}
        >
            <AutocompleteInput
                shouldRenderSuggestions={val => val && val.length >= 2 }
                optionText="name"
                optionValue="id" />
        </ReferenceInput>
        <NumberInput label="Transaction ID" source="transactionId||eq" alwaysOn/>
        <NumberInput label="transactionParentId" source="transactionParentId||eq"/>
        <AutocompleteArrayInput
            alwaysOn
            label="Stato"
            source="stateMachine.status||in"
            choices={status}
            translateChoice={false}
            allowEmpty={false}/>
    </Filter>
);

export function JobManyList (props) {
    return (
      <List {...props} actions={<ListActions />} filters={<JobManyFilter />}>
        <Datagrid rowClick="show">
          <TextField label='Transaction ID'  source="transactionId"/>
          <TextField label="Type" source="type" />
          <ReferenceField
            label="Mission"
            reference={`${Permissions.missionV2.feature}`}
            source="missionId"
            link="show"
          >
            <TextField source="id" />
          </ReferenceField>
          <ReferenceField
            label="Punto di Vendita"
            reference={`${Permissions.sellingPoint.feature}`}
            source="sellingPointId"
            link="show"
          >
            <TextField source="name" />
          </ReferenceField>
          <ReferenceField
            label="Warehouse Location"
            reference={`${Permissions.warehouseLocation.feature}`}
            source="warehouseLocationId"
            link="show"
          >
            <TextField source="name" />
          </ReferenceField>
          <TextField label="Duration (Minutes)" source="durationMinutes" />
          <TextField label="Max Advance (Minutes)" source="maxAdvanceMinutes" />
          <TextField label="STATO" source="stateMachine.status" />
        </Datagrid>
      </List>
    )
}
