import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Typography, AppBar, Toolbar, Card, Button } from "@material-ui/core";
import { useRefresh } from "react-admin";
import { Refresh } from "@material-ui/icons";

const useStyles = makeStyles(() => ({
	title: {
		flexGrow: 1,
	},
	btn: {
		flexGrow: 0,
		flexShrink: 0,
	},
}));

export function FormCallPlannerTask(props) {
	const { task } = props;
	const classes = useStyles();
	const refresh = useRefresh();

	const handleRefresh = () => {
		refresh();
	};

	return (
		<Card>
			<AppBar color="default" position="static">
				<Toolbar>
					<Typography variant="h5" className={classes.title}>
						{task.label}
					</Typography>
					{task.status !== "DONE" &&
						task.status !== "CANCELLED" && (
						<Button
							className={classes.btn}
							color="primary"
							variant="contained"
							onClick={handleRefresh}
							startIcon={<Refresh />}
						>
							Verifica completamento
						</Button>
					)}
				</Toolbar>
			</AppBar>
		</Card>
	);
}
