import React from "react";
import { Create, SimpleForm, TextInput, required } from "react-admin";
import { TypeInput } from "../task/components/TypeInput";

export function MissionJobTagCreate(props) {
	return (
		<Create {...props}>
			<SimpleForm redirect={"show"}>
				<TextInput source="name" validate={required()} />
				<TypeInput label="Tipologia Servizio" source="jobTypes" multiple/>
			</SimpleForm>
		</Create>
	);
}
