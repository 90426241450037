import React, { useEffect, useRef, useState } from 'react';
import { Show, SimpleShowLayout, TextField, DateField, ReferenceField, NumberField, EditButton, usePermissions, showNotification, useDataProvider, useRefresh,   Create,
	NumberInput,
	SimpleForm,
	TextInput,
	DateTimeInput,
	required,
	Toolbar,
	SelectInput,

} from 'react-admin';
import { useForm } from 'react-final-form';
import { Permissions } from '../../../permissions/permissions';
import StateMachineReferenceField from '../../../components/StateMachineReferenceField';
import Aside from '../inheritance/BaseAsideWithUser';
import { Box, Typography, InputLabel, Button, Dialog, DialogContent, DialogActions, DialogTitle, IconButton, Select, MenuItem } from '@material-ui/core'; 
import { Grid, Container } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import JobList from './job/JobList';
import { useHistory } from "react-router-dom";
import  { StateMachineFieldWithActions } from '../../../components/StateMachineFieldWithActions';
import AccessTimeIcon from '@material-ui/icons/AccessTime';
import CloseIcon from '@material-ui/icons/Close';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';

import { useDispatch, useStore } from 'react-redux';
import { debounce } from '../../../utils/debounce';
import AsyncSelect from 'react-select/async';
import { SendToRoutingButton } from './components/SendToRoutingButton';
import { ForceBackToBePlannedButton } from './components/ForceBackToBePlannedButton';
import { SelectRoutingAccount } from '../../../components/SelectRoutingAccount';


const useStyles = makeStyles((theme) => ({
    root: {
      flexGrow: 1,
      padding: theme.spacing(1)
    },
	toolbar: {
        padding: theme.spacing(2),
        display: 'flex',
        justifyContent: 'flex-end'
    },
	closeButton: {
        position: 'absolute',
        right: theme.spacing(0),
        top: theme.spacing(0),
        padding: theme.spacing(1)
	},
	dialogContent: {
		padding: 0,
		margin: '30px 30px 0',
	}
}));

export function MissionShow(props) {
	const classes = useStyles();
	return (
		<Container maxWidth="xl" className={classes.root}>
			<Grid container spacing={3}>
				<Grid item xl={12} lg={12} md={12} xs={12}>
				<Show {...props} aside={<Aside props={props}></Aside>} actions={<MissionActions />}>
					<SimpleShowLayout>
						<ReferenceField
							label="Luoghi"
							source="placeId"
							link="show"
							reference={Permissions.place.feature}
						>
							<TextField source="name" />
						</ReferenceField>
						<DateField locales="it-IT" label="Data massima di inizio" source="maxExecutionDate" showTime />
						<DateField label="Data stimata di inizio" locales="it-IT" source="estimatedStartDate" showTime />
						<DateField locales="it-IT" label="Data di inizio" source="startDate" showTime />
						<DateField locales="it-IT" label="Data di fine" source="endDate" showTime />
						<NumberField label="Durata missione stimata" source="duration" />
						<MissionDuration label="Durata missione effettiva" />
						<NumberField label="Stima tempi morti" source="downtimeDuration" />
						<TextField label="Valore missione" source="value" />
						<TextField label="Commento" source="comment" />
						<ReferenceField
							link='show'
							label="Corriere"
							source="deliveryManId"
							reference={Permissions.deliveryMan.feature}
						>
							<TextField source="mainUser.label" />
						</ReferenceField>
						<ReferenceField
							link='show'
							label="Corriere suggerito"
							source="suggestedDeliveryManId"
							reference={Permissions.deliveryMan.feature}
						>
							<TextField source="mainUser.label" />
						</ReferenceField>
						<ReferenceField
							label="Hub"
							source="hubId"
							link="show"
							reference={Permissions.hub.feature}
						>
							<TextField source="name" />
						</ReferenceField>
						<ReferenceField
							label="Missione Ricorrente"
							source="recurringMissionId"
							link="show" 
							reference={Permissions.recurringMission.feature}
						>
							<TextField source="id" />
						</ReferenceField>
						<DateField label="Data massima esecuzione ricorrente" source="maxExecutionDateRecurringMission"/>
						<SelectRoutingAccount label="Routing account" source="routingAccountId" show/>
						<TextField label="Errore di routing" source="routingError" />
						<TextField label="Archived" source="archived" />
						<TextField label="Tipo di fallimento" source="failureType" />
						<TextField label="Priorità" source="priority" />
						<StateMachineReferenceField
							label="Stato"
							source="stateMachine"
							reference={`${Permissions.missionV2.feature}_${Permissions.missionV2.subFeatures.stateMachine.name}`}>
						</StateMachineReferenceField>
						<StateMachineFieldWithActions label="Actions" reference={`${Permissions.missionV2.feature}_${Permissions.missionV2.subFeatures.stateMachine.name}`} source='stateMachineId' />
						<CustomActionMission/>
					</SimpleShowLayout>
				</Show>
				</Grid>
			</Grid>
			<Grid container spacing={3}>
				<Grid item xl={12} lg={12} md={12} xs={12} sm={12}>
					<JobList id={props.id} />
				</Grid>
			</Grid>
		</Container>
	);
};

function DeliveryManMissionShow(props) {
    const history = useHistory();

    if(!props.record.id) return null;
    
    return <Button	
        size="medium"
		style={{marginTop: '12px'}}
        onClick={() => {
            history.push(`/${Permissions.deliveryManMission.feature}/${props.record.id}/show`)
        }}
        variant="contained"
        color="primary">
        Apri vista corriere
    </Button>
}

function MissionDuration(props) {
	const seconds = props.record.actualDuration
	const minutes = Math.floor(seconds / 60) % 60;
	const hours = Math.floor(seconds / 3600);
	const remainingSeconds = seconds % 60;
	return (
		<Box>
			<InputLabel >
				<Typography variant='subtitle2'>{props.label}</Typography>
			</InputLabel>
			<Typography variant='subtitle2' >{`${hours}h ${minutes}m ${remainingSeconds}s`}</Typography>
		</Box> 
	);
}

function CustomToolbar(props) {
	const form = useForm();
	let btnRef = useRef();

	return (
		<Toolbar {...props} style={{
			display: 'flex',
			justifyContent: 'flex-end',
			backgroundColor: 'transparent',
		}}>
			<Button ref={btnRef}  variant='contained' startIcon={<AccessTimeIcon />} onClick={() => {

				if (btnRef.current && !btnRef.current.disabled) {
					btnRef.current.disabled = true;
					
					const state = form.getState();
					const errors = state.errors;
					if (Object.keys(errors).length > 0) {
						form.submit();
						return;
					}
					props.onSubmit(form.getState().values);
				}
			}} color='primary'>
				posticipa
			</Button>
		</Toolbar>
	);
}

const customStyles = {
    control: (base, state) => ({
      ...base,
      borderColor: state.isFocused ? '#086d40' : 'transparent',
      boxShadow: state.isFocused ? `0 0 0 1px #086d40` : 'none',
      '&:hover': {
        borderColor: state.isFocused ? '#086d40' : 'rgba(0, 0, 0, 0.2)',
      },
      backgroundColor: 'transparent',
	  marginBottom: '20px'
    }),
    option: (base, { isFocused, isSelected }) => ({
      ...base,
      backgroundColor: isSelected ? '#086d40' : isFocused ? '#bde4c9' : null,
      color: isSelected ? 'white' : 'black',
      ':active': {
        backgroundColor: !isSelected ? '#bde4c9' : '#086d40',
      },
    }),
};

function SuggestedDeliveryMan(props) {
    const dataProvider = useDataProvider();
	const form = useForm();
	const store = useStore();
	const [status, setStatus] = useState('INITIAL');

	useEffect(() => {
		if (status === "INITIAL") {
			setStatus("SETTING_PREFILL_OPTION");
			if(props.prefillOption) {
				form.change('suggestedDeliveryManId', props.prefillOption);
			}
		}
	}, [form, props.prefillOption, status]);

	const deliveryMan = props.prefillOption ? store.getState().admin.resources.deliveryMan.data[props.prefillOption] : undefined;

    const debouncedLoadOptions = debounce((inputValue, callback) => {
        if (inputValue.length >= 3) {
            dataProvider.getList(Permissions.deliveryMan.feature, {
                filter: { "mainUser.label": inputValue },
                pagination: { page: 1, perPage: 5000 }
            })
            .then(({ data }) => {
                callback(data.map(item => ({ value: item.id, label: `${
                item ? `${item.mainUser.id} - ${item.mainUser.label}` : ""
              }` })));
            })
            .catch(e => {
                callback([]);
            });
        } else {
            callback([]);
        }
    }, 500);

	const boxStyle = {
		backgroundColor: 'rgba(0, 0, 0, 0.09)',
		paddingTop: '5px',
		borderTopLeftRadius: '4px',
		borderTopRightRadius: '4px',
	}

    return (<Box style={boxStyle} fullWidth>   
                <Typography variant='caption' style={{paddingLeft: "15px", color: "rgba(0, 0, 0, 0.54)"}}>Corriere Suggerito</Typography>
				<AsyncSelect
						label="Corriere suggerito"
						styles={customStyles}
						noOptionsMessage={() => "Nessun Corriere trovato"}
						isClearable
						isSearchable={true}
						placeholder={"Corriere suggerito"}
						loadOptions={debouncedLoadOptions}
						defaultOptions={[]}
						onChange={e => {
							form.change('suggestedDeliveryManId', e?.value);
						}}
						defaultValue={deliveryMan ? { value: deliveryMan.id, label: `${deliveryMan.mainUser.id} - ${deliveryMan.mainUser.label}`} : undefined}
					/>
		</Box>);
}

function MissionActions({ basePath, data }) {
	const classes = useStyles();
	const { permissions } = usePermissions();
	const [open, setOpen] = useState(false);
	const dataProvider = useDataProvider();

	const [status, setStatus] = useState('INITIAL')
	const [rows, setRows] = useState([]);
	const [jobs2Send, setJobs2Send] = useState({});
	const [missionDto, setMissionDto] = useState(undefined);
	const dispatch = useDispatch()
	const refresh = useRefresh();

	useEffect(() => {
		if (status === 'FETCH_DATA' && data) {
			setStatus('FETCHING_DATA');
			dataProvider.getList(Permissions.missionJob.feature, {
				filter: {
					"missionId||eq": data.id,
					'stateMachine.status||in': ['TO_DO']
				},
				pagination: { page: 1, perPage: 50 }
			})
				.then(response => {
					if (!response || !response.data || response.data.length === 0) {
						throw new Error('No data found');
					}

					setRows(response.data);
					setOpen(true);
					setStatus('DATA_FETCHED');
				})
				.catch((e) => {
					if(e.message === 'No data found') {
						dispatch(showNotification("Non sono presenti servizi in TO_DO e quindi posticipabili. Rimetterli in TO_DO prima di posticiparli", 'info'));
					} else {
						dispatch(showNotification("Errore durante la generazione dei dati", 'error'));
					}
					setStatus('INITIAL');
				})
		}
	}, [data, dataProvider, dispatch, status]);

	useEffect(() => {
		if (status === 'SEND_DATA') {
			setStatus('SENDING_DATA');
			const jobs = Object.keys(jobs2Send).map(key => {
				if (!jobs2Send[key].duplicateInNewMission) {
					return {
						id: key,
						duplicateInNewMission: jobs2Send[key].duplicateInNewMission
					};
				} else {
					return {
						id: key,
						duplicateInNewMission: jobs2Send[key].duplicateInNewMission,
						keepLoad: jobs2Send[key].keepLoad,
						keepUnload: jobs2Send[key].keepUnload,
					};
				}
			});

			dataProvider('POST_OFF_RESOURCE', `${Permissions.missionV2.feature}/${data.id}/${Permissions.missionJob.feature}/return`, {
				data: {
					jobs,
					missionDto: Object.keys(jobs2Send).some(key => jobs2Send[key].duplicateInNewMission) ? missionDto : undefined
				}
			})
				.then(() => {
					close();
					refresh();
				})
				.catch((e) => {
					if(e && e.validationResults) {
						dispatch(showNotification(parseValidationMessage(e), 'error'));
					} else {
						dispatch(showNotification("Errore durante l'invio dei dati", 'error'));
					}
					setStatus('DATA_FETCHED');
				})
		}
	}, [data, jobs2Send, dataProvider, dispatch, refresh, status, missionDto]);

	if (!data) return null;

	const close = () => {
		setOpen(false);
		setRows([]);
		setJobs2Send({});
		setMissionDto(undefined)
		setStatus('INITIAL');
	}

	return (
		<div className={classes.toolbar}>
			<SendToRoutingButton record={data}/>
			<EditButton basePath={basePath} record={data} />
			{
				data && data.stateMachine && ['DELIVERING', 'IN_PROGRESS'].includes(data.stateMachine.status) && permissions && permissions.includes(`${Permissions.missionV2.feature}-${Permissions.missionV2.actions.ReturnJobs}`) &&
				<Button startIcon={<AccessTimeIcon />} onClick={() => { setStatus('FETCH_DATA') }} color='primary'>
					posticipa
				</Button>
			}
			<Dialog
				open={open && status === 'DATA_FETCHED'}
				onClose={() => close()}
				scroll={'paper'}
				maxWidth='lg'
				fullWidth={true}
			>
				<DialogTitle id="scroll-dialog-title">Rientro Servizi Missioni</DialogTitle>
				<IconButton aria-label="close" color="primary" className={classes.closeButton} onClick={close}>
					<CloseIcon />
				</IconButton>
				<DialogContent dividers={true} className={classes.dialogContent}>
					<TableContainer component={Paper}>
						<Table sx={{ minWidth: 650 }} aria-label="simple table">
							<TableHead>
								<TableRow>
									<TableCell>type</TableCell>
									<TableCell>sellingPointLabel</TableCell>
									<TableCell>warehouseLocationLabel</TableCell>
									<TableCell colSpan={2} align='center'>Azioni</TableCell>
								</TableRow>
							</TableHead>
							<TableBody>
								{rows.map((row) => (
									<TableRow
										key={row.id}
									>
										<TableCell>{row.type}</TableCell>
										<TableCell>{row.sellingPointLabel}</TableCell>
										<TableCell>{row.warehouseLocationLabel}</TableCell>
										<TableCell align='center'>
											<Select
												style={{ width: '170px' }}
												value={jobs2Send[row.id] ? jobs2Send[row.id].duplicateInNewMission ? 'duplicate' : 'delete' : 'ignore'}
												onChange={(event) => {
													const data = JSON.parse(JSON.stringify(jobs2Send));
													if (event.target.value === 'duplicate') {
														data[row.id] = {
															duplicateInNewMission: true,
															keepLoad: jobs2Send[row.id] && jobs2Send[row.id].keepLoad ? true : false,
															keepUnload: jobs2Send[row.id] && jobs2Send[row.id].keepUnload ? true : false
														};
													} else if (event.target.value === 'delete') {
														data[row.id] = {
															duplicateInNewMission: false,
															keepLoad: false,
															keepUnload: false
														};
													} else {
														delete data[row.id];
													}
													setJobs2Send(data);
												}}
											>
												<MenuItem value={'ignore'}>Lascia da fare</MenuItem>
												<MenuItem value={'duplicate'}>Ricrea in altra data</MenuItem>
												<MenuItem value={'delete'}>Cancella</MenuItem>
											</Select>
										</TableCell>
										<TableCell align='center'>
											<Select
												disabled={jobs2Send[row.id] && jobs2Send[row.id].duplicateInNewMission ? false : true}
												style={{ width: '170px' }}
												value={jobs2Send[row.id] ? jobs2Send[row.id].duplicateInNewMission && jobs2Send[row.id].keepLoad && jobs2Send[row.id].keepUnload ? 'reuse' : jobs2Send[row.id].duplicateInNewMission && jobs2Send[row.id].keepLoad && !jobs2Send[row.id].keepUnload ? 'onlyLoad' : jobs2Send[row.id].duplicateInNewMission && !jobs2Send[row.id].keepLoad && jobs2Send[row.id].keepUnload ? 'onlyUnLoad' : 'ignore' : 'ignore'}
												onChange={(event) => {
													const data = JSON.parse(JSON.stringify(jobs2Send));

													switch (event.target.value) {
														case 'reuse':
															data[row.id].keepLoad = true;
															data[row.id].keepUnload = true;
															break;
														case 'onlyLoad':
															data[row.id].keepLoad = true;
															data[row.id].keepUnload = false;
															break;
														case 'onlyUnLoad':
															data[row.id].keepLoad = false;
															data[row.id].keepUnload = true;
															break;
														case 'ignore':
														default:
															delete data[row.id].keepLoad;
															delete data[row.id].keepUnload;
															break;
													}

													setJobs2Send(data);
												}}
											>
												<MenuItem value={'ignore'}>nessuno</MenuItem>
												<MenuItem value={'reuse'}>usa entrambi</MenuItem>
												<MenuItem value={'onlyLoad'}>solo carico</MenuItem>
												<MenuItem value={'onlyUnLoad'}>usa scarico</MenuItem>
											</Select>
										</TableCell>
									</TableRow>
								))}
							</TableBody>
						</Table>
						{Object.keys(jobs2Send).some(key => jobs2Send[key].duplicateInNewMission) && <Create
							basePath={Permissions.missionV2.feature}
							resource={Permissions.missionV2.feature}
						>
						<SimpleForm toolbar={<CustomToolbar onSubmit={(data) => {
							setMissionDto(data);
							setStatus('SEND_DATA');
						}} />}>
							<DateTimeInput
								fullWidth
								locales="it-IT"
								validate={[
									required("campo obbligatorio"),
									(value) => {
										if (!value || !isNaN(new Date(value))) return;

										return 'Data non valida';
									}
								]}
								label="Data massima di inizio"
								source="maxExecutionDate"
							/>
							<DateTimeInput
								fullWidth
								validate={[
									(value) => {
										if (!value) return;

										if (isNaN(new Date(value))) return 'Data non valida';
									}
								]}
								locales="it-IT"
								label="Data stimata di inizio"
								source="estimatedStartDate"
							/>
							<NumberInput
								fullWidth
								defaultValue={0}
								validate={[required("campo obbligatorio")]}
								label="Valore missione"
								source="value"
							/>
							<NumberInput
								fullWidth
								defaultValue={data.downtimeDuration || 0}
								validate={[required("campo obbligatorio")]}
								label="Stima tempi morti"
								source="downtimeDuration"
							/>
							<SelectInput
								source="priority"
								label="Priorità"
								fullWidth
								initialValue={data.priority || 'medium'}
								value={data.priority || 'medium'}
								choices={[
									{ id: 'low', name: 'Bassa' },
									{
									id: 'medium',
									name: 'Media',
									},
									{
									id: 'high',
									name: 'Alta',
									},
									{
									id: 'critical',
									name: 'Critical',
									},
								]}
								/>
							<TextInput fullWidth defaultValue={data.comment} label="Commento" source="comment" />
							<SuggestedDeliveryMan alwaysOn prefillOption={data.suggestedDeliveryManId} />
						</SimpleForm>
					</Create>}
					</TableContainer>
					{!Object.keys(jobs2Send).some(key => jobs2Send[key].duplicateInNewMission) && <DialogActions>
						<Button disabled={Object.keys(jobs2Send).length === 0} variant='contained' startIcon={<AccessTimeIcon />} onClick={() => {
							setStatus('SEND_DATA');
						}} color='primary'>
							posticipa
						</Button>
					</DialogActions>}
				</DialogContent>
			</Dialog>
		</div>
	);
}

function parseValidationMessage(validationMessage) {
	function parseMessage(message, depth = 0) {
		let result = '';
		const indent = '  '.repeat(depth);

		if (message.property && message.target) {
		result += `${indent}Proprietà '${message.property}':\n`;
		result += parseTarget(message.target, depth + 1);
		}

		if (message.constraints) {
		for (const [constraint, msg] of Object.entries(message.constraints)) {
			result += `${indent}  Vincolo '${constraint}': ${msg}\n`;
		}
		}

		if (message.children && message.children.length > 0) {
		message.children.forEach(child => {
			result += parseMessage(child, depth + 1);
		});
		}

		return result;
	}

	function parseTarget(target, depth) {
		let result = '';
		const indent = '  '.repeat(depth);

		for (const [key, value] of Object.entries(target)) {
		if (typeof value === 'object' && !Array.isArray(value)) {
			result += `${indent}${key.charAt(0).toUpperCase() + key.slice(1)}:\n`;
			result += parseTarget(value, depth + 1);
		} else if (Array.isArray(value)) {
			result += `${indent}${key.charAt(0).toUpperCase() + key.slice(1)}:\n`;
			result += parseArray(value, depth + 1);
		} else {
			result += `${indent}${key.charAt(0).toUpperCase() + key.slice(1)}: ${value}\n`;
		}
		}

		return result;
	}

	function parseArray(array, depth) {
		let result = '';
		const indent = '  '.repeat(depth);

		array.forEach((item, index) => {
		result += `${indent}[${index}]:\n`;
		result += parseTarget(item, depth + 1);
		});

		return result;
	}

	let result = '';
	if (validationMessage.validationResults) {
		validationMessage.validationResults.forEach(message => {
		result += parseMessage(message);
		});
	}

	if (validationMessage.code !== undefined) {
		result += `Codice: ${validationMessage.code}\n`;
	}

	return result;
}

function CustomActionMission(props) {
	return (
		<Box display='flex' alignItems="center" gridGap="10px">
			<DeliveryManMissionShow {...props} />
			<ForceBackToBePlannedButton {...props}/>
		</Box>
	);
}

