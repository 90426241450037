import React from "react";
import {
	TextField,
	List,
	Datagrid,
	TextInput,
} from "react-admin";
import Filter from '../../../../inheritance/BaseFilter';
import { TypeInput } from "../task/components/TypeInput";

function TagFilter(props) {
    return (
        <Filter {...props}>
            <TextInput label="Name" source="name" alwaysOn />
			<TypeInput label="Tipologia servizio" source="jobTypes"/>
        </Filter>
    );
}

export default function MissionJobTagList(props) {
	return (
		<List {...props} filters={<TagFilter />} >
			<Datagrid rowClick="show">
				<TextField source="name" />
			</Datagrid>
		</List>
	);
}
